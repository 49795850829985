import * as React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import Table from '../components/Table';

const LastMonthPage = ({ data, location }) => {
  const title = 'Last Month Downloads';

  const processNodes = (nodes) => {
    return nodes
      .filter((node) => node.lastMonthDownloads > 0)
      .map((node) => ({
        ...node,
        numDownloads: node.lastMonthDownloads,
        topListNumDownloads: node.lastMonthDownloads,
      }))
      .sort((a, b) => b.lastMonthDownloads - a.lastMonthDownloads);
  };

  const themes = processNodes(data.themes.nodes);
  const plugins = processNodes(data.plugins.nodes);

  return (
    <Layout location={location} title={title}>
      <h1>{title}</h1>
      <>
        <p>Downloads for the previous month.</p>
        <h2>{`Themes Top 10`}</h2>
        {themes.length > 0 &&
          Table({
            data: themes.slice(0, 10),
            owner: true,
            number: true,
            downloads: true,
            toplistDownloads: false,
          })}
        <h2>{`Plugins Top 100`}</h2>
        {plugins.length > 0 &&
          Table({
            data: plugins.slice(0, 100),
            owner: true,
            number: true,
            downloads: true,
            toplistDownloads: false,
          })}
      </>
    </Layout>
  );
};

export const query = graphql`
  {
    plugins: allMatomoPlugin(
      sort: { fields: lastMonthDownloads, order: DESC }
      filter: { lastMonthDownloads: { gt: 0 } }
    ) {
      nodes {
        id
        idPath
        displayName
        description
        latestVersion
        lastUpdated
        owner
        numDownloads
        lastMonthDownloads
        repositoryUrl
        homepage
      }
    }
    themes: allMatomoTheme(
      sort: { fields: lastMonthDownloads, order: DESC }
      filter: { lastMonthDownloads: { gt: 0 } }
    ) {
      nodes {
        id
        idPath
        displayName
        description
        latestVersion
        lastUpdated
        owner
        numDownloads
        lastMonthDownloads
        repositoryUrl
        homepage
      }
    }
  }
`;

export default LastMonthPage;
